import type { Article } from '@/types';
import { i18n } from '@/i18n';

const { t } = i18n.global;

export function markInputValue(e: HTMLInputElement | MouseEvent) {
  if (e instanceof HTMLInputElement) {
    e.select();
  } else {
    const target = e.target as HTMLInputElement;
    target.select();
  }
}

export function formatNumber(value: number, minimumFractionDigits = 0, maximumFractionDigits = 2) {
  // if decimal value, always show maximumFractionDigits
  if (value % 1 !== 0) {
    minimumFractionDigits = maximumFractionDigits;
  }

  return Intl.NumberFormat('sv-SE', {
    minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits + 1, // Add one to maximumFractionDigits to make sure we don't round up
  })
    .formatToParts(value)
    .map((part) => {
      if (part.type === 'fraction' && part.value.length > maximumFractionDigits) {
        return part.value.slice(0, -1); // Remove the extra digit we added to maximumFractionDigits
      }
      return part.value;
    })
    .join('');
}

export function getArtPrefix(value: string) {
  return value?.replace(/[0-9][0-9]([^0-9].*)?$/, '') ?? null;
}

export function getArtHash(value: string) {
  return value?.replace(/[0-9][0-9]([^0-9].*)?$/, 'xx$1') ?? null;
}

export const languageMap = {
  'sv-SE': 'SWEDISH',
  'en-US': 'ENGLISH',
  'fi-FI': 'FINNISH',
  'nb-NO': 'NORWEGIAN',
  'da-DK': 'DANISH',
  'de-DE': 'GERMAN',
} as { [key: string]: string };

export function getArticleTitle(article: Article) {
  const language = languageMap[String(i18n.global.locale.value)] || 'SWEDISH';
  return article?.description?.[language] || article?.descriptionExt || article?.description || article?.ItemLongDescription || article?.ItemDescription || '';
}

const unitConversion = {} as {
  locale: string;
  short: { [key: string]: string };
  full: { [key: string]: string };
};

export function convertUnit(unit: string, { short = false, lowercase = false } = {}) {
  if (String(i18n.global.locale.value) !== unitConversion.locale) {
    unitConversion.locale = String(i18n.global.locale.value);
    unitConversion.short = {
      ark: t('unit.short.ark'),
      b: t('unit.short.b'),
      bnt: t('unit.short.bnt'),
      fl: t('unit.short.fl'),
      flm: t('unit.short.flm'),
      g: t('unit.short.g'),
      kg: t('unit.short.kg'),
      kkg: t('unit.short.kkg'),
      krt: t('unit.short.krt'),
      l: t('unit.short.l'),
      lpm: t('unit.short.lpm'),
      m: t('unit.short.m'),
      m2: t('unit.short.m2'),
      m3: t('unit.short.m3'),
      ml: t('unit.short.ml'),
      mm: t('unit.short.mm'),
      p: t('unit.short.p'),
      pal: t('unit.short.pal'),
      p2: t('unit.short.p2'),
      par: t('unit.short.par'),
      pkk: t('unit.short.pkk'),
      pkt: t('unit.short.pkt'),
      pås: t('unit.short.pas'),
      rul: t('unit.short.rul'),
      set: t('unit.short.set'),
      st: t('unit.short.st'),
      säc: t('unit.short.sac'),
      t: t('unit.short.t'),
      ton: t('unit.short.ton'),
      tub: t('unit.short.tub'),
    };
    unitConversion.full = {
      ark: t('unit.full.ark'),
      b: t('unit.full.b'),
      bnt: t('unit.full.bnt'),
      fl: t('unit.full.fl'),
      flm: t('unit.full.flm'),
      g: t('unit.full.g'),
      kg: t('unit.full.kg'),
      kkg: t('unit.full.kkg'),
      krt: t('unit.full.krt'),
      l: t('unit.full.l'),
      lpm: t('unit.full.lpm'),
      m: t('unit.full.m'),
      m2: t('unit.full.m2'),
      m3: t('unit.full.m3'),
      ml: t('unit.full.ml'),
      mm: t('unit.full.mm'),
      p: t('unit.full.p'),
      pal: t('unit.full.pal'),
      p2: t('unit.full.p2'),
      par: t('unit.full.par'),
      pkk: t('unit.full.pkk'),
      pkt: t('unit.full.pkt'),
      pås: t('unit.full.pas'),
      rul: t('unit.full.rul'),
      set: t('unit.full.set'),
      st: t('unit.full.st'),
      säc: t('unit.full.sac'),
      t: t('unit.full.t'),
      ton: t('unit.full.ton'),
      tub: t('unit.full.tub'),
    };
  }

  const value = unitConversion[short ? 'short' : 'full'][unit] || unit || '';
  return lowercase ? value.toLowerCase() : value;
}
